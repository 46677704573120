import { v4 as uuidv4 } from "uuid";
import LogicComparisonClass from "@/custom/class/LogicExpression/LogicComparisonClass.js";

export default class LogicGroupClass {
  constructor(
      operator = '$OR',
      list = [ new  LogicComparisonClass()],
      isGroup = true,
  ) {
    this.list = list
    this.isGroup = isGroup
    this.operator = operator
  }
}