<template>
<div class="animate__animated animate__fadeIn animate__faster pb-50">

  <div class="d-flex align-items-center mx-1 my-50 position-relative ">
    <custom-input
      class="custom-input-field m-0"
      :possibleValues="{}"
      v-model="comparison.value1"
      :advanced="false"
      autofocus
    />

    <b-button class="mx-50 font-weight-bolder comparison-operator-btn" variant="none" :id="'comparison-changer-' + uuid">
      {{ operatorsMap[comparison.operator]}}
    </b-button>

    <custom-input
      class="custom-input-field m-0"
      :possibleValues="{}"
      v-model="comparison.value2"
      :advanced="false"
    />

    <slot name="delete-btn">
      <b-button class="delete-comparison-btn" variant="outline-danger" @click="$emit('delete-comparison')">
        <b-icon icon="x" variant="danger"/>
      </b-button>
    </slot>
  </div>


  <b-tooltip :target="'comparison-changer-' + uuid" triggers="focus" variant="black" placement="top">
    <div class="d-flex flex-wrap justify-content-center align-items-center comparison-list-container">
      <b-button class="m-25 comparison-operator-btn small font-weight-bolder" v-for="op in operators" variant="none" :key="op.id" @click="changeOperator(op)">
        {{op.symbol}}
      </b-button>
    </div>
  </b-tooltip>

</div>
</template>

<script>
import { 
  BRow, 
  BCol, 
  BContainer, 
  BButton, 
  BFormTags, 
  BCollapse,
  BTooltip,
} from "bootstrap-vue";
import VSelect from "vue-select";
import Comparisions from "@/views/pages/middleware/MiddlewareManagement/Components/Comparison/refactored/Comparisons.js"
import { v4 as uuidv4 } from "uuid";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue"


export default {
  components: {
    BRow,
    BCol,
    BContainer,
    CustomInput,
    BButton,
    VSelect,
    BFormTags,
    BCollapse,
    BTooltip,
  },
  props: {
    variables: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [Object, Array],
      required: true,
    },
    isRoot:{
      type: Boolean,
      default: false,
    },
    comparator:{
      type: String,
      default: "AND"
    }

  },
  data() {
    return {
      uuid: uuidv4(),
      operators: new Comparisions().items,
      operatorsMap: new Comparisions().map
    }
  },
  computed: {
    comparison: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  mounted() {
  },
  methods: {
    changeOperator(op){
      this.comparison.operator = op.value

      document.activeElement.blur();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';
.custom-input-field{
  min-width: 100px;
  max-width: 300px;
}

.delete-comparison-btn{
  position: absolute;
  padding: 0px;
  top: -5px;
  right: -8px;
  background-color: $dark-blue;
  border-radius: 50%;
  transition: all 0.2s;
  
  &:hover, &:focus{
    background-color: mix($danger, $dark-blue, 20) !important;
    
  }
  &:active{
    outline: 1px solid $light;
  }
}

.comparison-operator-btn{
  margin-top: 3px;
  background-color: rgb(36, 164, 96);
  color: var(--black);
  border-radius: 5px;
  padding: 5px;
  min-width: 30px;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.6);
  &:hover , &:focus{
    background-color: rgb(43, 189, 111);
  }
  &:active{
    filter: brightness(1.2);
  }
}

.comparison-list-container{
  max-width: 150px;
}
</style>