export default class Comparisons {
  constructor() {
    this.items = [
      { 
        id: 1, 
        name: "EQUAL", 
        value: "$EQ", 
        symbol: "=="
      },
      { 
        id: 2, 
        name: "NOT EQUAL", 
        value: "$NEQ", 
        symbol: "!="
      },
      { 
        id: 3, 
        name: "LESS THAN", 
        value: "$LT", 
        symbol: "<"
      },
      { 
        id: 4, 
        name: "LESS/EQUAL", 
        value: "$LTE", 
        symbol: "≤"
      },
      { 
        id: 5, 
        name: "GREATER", 
        value: "$GT", 
        symbol: ">"
      },
      { 
        id: 6, 
        name: "GREATER/EQUAL", 
        value: "$GTE", 
        symbol: "≥"
      },
    ];

    
      let r = {}
      this.items.forEach((el)=>{
        r[el.value] = el.symbol
      })
      
      this.map = r
    

  }

}
