<template>
  <div>
    <b-button class="mx-25 add-comparison-btn" variant="none" :id="'add-comparison-btn-'+componentID">
      <b-icon icon="plus" scale="1.2" variant="success"/>
    </b-button>


    <b-tooltip :target="'add-comparison-btn-'+componentID" triggers="focus" placement="top" custom-class="faded-success-tooltip">
      <b-button variant="outline-black" class="py-25 px-1 mr-50 mt-25 d-block w-100" @click="addComparison()">comparison</b-button>
      <div align="center" class="font-weight-bolder m-0 text-black">
        <b-icon icon="dash" scale="1.5" class=""/>
        <b-icon icon="diamond-fill" scale="0.4" class=""/>
        <span class="mx-50">OR</span>
        <b-icon icon="diamond-fill" scale="0.4" class=""/>
        <b-icon icon="dash" scale="1.5" class=""/>
      </div>
      <b-button variant="outline-black" class="py-25 px-1 d-block w-100" @click="addGroup()">group</b-button>
    </b-tooltip>
    
  </div>
</template>

<script>
import { 
  BRow, 
  BCol, 
  BContainer, 
  BButton, 
  BFormTags, 
  BCollapse,
  BTooltip,
} from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BButton,
    BFormTags,
    BCollapse,
    BTooltip,
  },
  props: {
    isInside:{
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      componentID: uuidv4()
    }
  },
  methods: {
    addGroup() {
      this.$emit("addGroup",this.isInside)
    },
    addComparison() {
      this.$emit("addComparison",this.isInside)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

$group-bg: $dark-blue;

.add-comparison-btn{
  background-color:  $group-bg;
  color: $success !important;
  border: 2px solid transparentize($success, 0.5);
  transition: all 0.25s;
  border-radius: 50%;
  padding: 2px;
  &:hover, &:focus{
    border-color: $success;
    background-color: rgb(27, 34, 58);
  }
}

</style>

<style lang="scss">
  
.faded-success-tooltip{
  $faded-bg-color: #24a562;
  .tooltip-inner{
    background-color: $faded-bg-color !important;
  }
  .arrow:before{
    border-top-color:  $faded-bg-color !important;
  }
}
</style>