<template>
  <div class="d-flex align-items-center flex-wrap animate__animated animate__fadeIn animate__faster">
    
    <div 
      class="group-container d-flex align-items-center" 
      :class="{
        'colorized': !isRoot,
        'is-negation': this.isNegation
      }"
    >
      <template v-if="!isRoot">

        <b-button
          :id="'not-operator-indicator-'+ uuid" 
          variant="none" 
          class="not-operator-indicator" 
          :class="{'is-negation': this.isNegation}"
          @click="toggleNotOperator()"
        >
          <b-icon icon="exclamation" scale="1.3"/>
        </b-button>
        
        <b-tooltip :target="'not-operator-indicator-'+ uuid" triggers="hover" variant="dark" placement="top" :delay="{ show: 500, hide: 0}" >
          <div>
            <b>NOT</b> operator 
            <hr variant="light" class="border-secondary my-25">
            inverts the result of this group
          </div>
        </b-tooltip>
      </template>
      <div class="w-100 d-flex flex-wrap">
        <div class="d-flex align-items-center justify-content-start mx-25 flex-wrap" align="center">

          <template v-for="(item , idx) in groupData.list">

            <b-button class="logic-operator-toggle" align="center" v-if="idx != 0" variant="none" @click="changeLogicalOperator()">
              {{comparatorLabel[groupData.operator]}}
              <div>
                <b-icon icon="arrow-left-right" scale="0.75" class="mt-25"/>
              </div>
            </b-button>

            <boolean-expression-group v-if="item.isGroup" class="w-fit-content " v-model="groupData.list[idx]" @removeGroup="removeComparsion(idx)" @addComparison="addComparison"/>
  
            <comparison v-else v-model="groupData.list[idx]" @delete-comparison="removeComparsion(idx)" :key="'comparison-' + item.uuid">
              <template  #delete-btn v-if="isRoot && groupData.list.length==1">
                <span/>
              </template>
            </comparison>
  
          </template>


          <add-comparison-btn :isInside="true" @addGroup="addGroup" @addComparison="addComparison"/>

        </div>
      </div>
    </div>
    
    <!-- <add-comparison-btn v-if="isRoot" :isInside="false" @addGroup="addGroup" @addComparison="addComparison"/> -->

    <!-- {{groupData}} -->
  </div>
</template>

<script>
import { 
  BRow, 
  BCol, 
  BContainer, 
  BButton, 
  BFormTags, 
  BCollapse,
  BTooltip,
} from "bootstrap-vue";
import Logicalcomparison from "@/views/pages/middleware/MiddlewareManagement/Components/Comparison/LogicalComparison.js";
import { v4 as uuidv4 } from "uuid";
import Comparison from "@/views/pages/middleware/MiddlewareManagement/Components/Comparison/refactored/Comparison.vue"
import AddComparisonBtn from '@/views/pages/middleware/MiddlewareManagement/Components/Comparison/refactored/AddComparisonBtn.vue'
import LogicGroupClass from '@/custom/class/LogicExpression/logicGroupClass';
import LogicComparisonClass from '@/custom/class/LogicExpression/LogicComparisonClass.js'
import Comparisons from "@/views/pages/middleware/MiddlewareManagement/Components/Comparison/refactored/Comparisons.js"

export default {
  components: {
    BooleanExpressionGroup: ()=> import("@/views/pages/middleware/MiddlewareManagement/Components/Comparison/refactored/BooleanExpressionGroup.vue"),
    BRow,
    BCol,
    BContainer,
    BButton,
    BFormTags,
    BCollapse,
    Comparison,
    BTooltip,
    AddComparisonBtn,
  },
  props: {
    variables: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [Object, Array],
      required: true,
    },
    isRoot:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {  
      comparatorLabel:{
        "$AND": "and",
        "$NAND": "and",
        "$OR": "or",
        "$NOR": "or",
      },
      uuid: uuidv4(),
    }
  },
  computed: {
    groupData: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isNegation(){
      const map = {
        "$NAND": true,
        "$NOR": true,
        "$AND": false,
        "$OR": false,
      }
      return map[this.groupData.operator]
    }
  },
  mounted() {
  },
  methods: {
    removeComparsion(idx){
      
      this.groupData.list.splice(idx , 1)
      if (this.groupData.list.length == 0){
        this.$emit('removeGroup')
      }

      if (this.isRoot && this.groupData.list.length == 0){
        this.addComparison(true)
      }
    },
    addComparison(isInside){
      let c = new LogicComparisonClass()
      if (isInside){
        this.groupData.list.push(c)
      }
      else{
        this.$emit("addComparison" , true)
      }
      
    },
    addGroup(isInside){
      if (isInside){
        this.groupData.list.push(new LogicGroupClass())
      } else {
        this.$emit("addComparison" , true)
      }
    },
    changeLogicalOperator(){
      const opMap = {
        "$OR": "$AND",
        "$NOR": "$NAND",
        "$AND": "$OR",
        "$NAND": "$NOR",
      }
      this.groupData.operator = opMap[this.groupData.operator]
            
    },
    toggleNotOperator(){
      const negationMap = {
        "$OR": "$NOR",
        "$AND": "$NAND",
        "$NOR": "$OR",
        "$NAND": "$AND",
      }
      this.groupData.operator = negationMap[this.groupData.operator]       
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';
*{
  overflow-wrap: break-word !important;
}
.group-container{
  border-radius: 8px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  padding: 0 5px;
  margin: 0 10px;
  position: relative;
  transition: all 0.2s;
  &.colorized{
    outline: 1px solid rgba(0, 0, 0, 0.4);
    background-color: rgb(18, 23, 39);
    border-left: 4px solid $success;
    border-right: 4px solid $success;
    &.is-negation{
      border-color: $danger;
    }
    &:hover{
      .not-operator-indicator{
        opacity: 0.8;
        &:hover{
          opacity: 1;
        }
      }
    }
  }
}
.logic-operator-toggle{
  $toggle-color: $light;
  font-weight: bolder;
  color: transparentize($toggle-color, 0.5);
  transition: all 0.25s;
  text-transform: uppercase;
  padding: 0 5px;
  &:hover{
    color: transparentize($toggle-color, 0.2);
  } 
  &:active{
    color: $toggle-color;

  }
}

.not-operator-indicator{
  text-transform: uppercase;
  color: $black;
  font-weight: bolder;
  opacity: 0;
  transition: all 0.2s;
  position: absolute;
  left: 0;
  top: 50%;
  padding: 0;
  transform: translate(-50%, -50%);
  background-color: $secondary;
  border-radius: 10px;
  border: 2px solid $black;
  z-index: 1;
  font-size: 18px;
  &.is-negation{
    color: $danger;
    border-color: $danger;
    background-color: $dark-blue;
    //background-color: red;
    opacity: 1 !important;
  }
}

</style>