import { v4 as uuidv4 } from "uuid";

export default class LogicComparisonClass {
  constructor(
      uuid = uuidv4(),
      value1 = "",
      value2 = "",
      operator = "$EQ",
  ) {
    this.uuid = uuid;
    this.value1 = value1
    this.value2 = value2
    this.operator = operator
  }
}