<template>
  <div class="w-100">


    <div class="logic-expression-container">
      <boolean-expression-group class="w-fit-content" isRoot v-model="logicExpressionData" v-if="logicExpressionData"/>
    </div>

  <!-- 
      <br><br><br><br><br>
      {{logicExpressionData}} -->

  </div>
</template>

<script>
import { 
  BRow, 
  BCol, 
  BContainer, 
  BButton, 
  BFormTags, 
  BCollapse,
} from "bootstrap-vue";
import Comparisons from "@/views/pages/middleware/MiddlewareManagement/Components/Comparison/refactored/Comparisons.js"
import { v4 as uuidv4 } from "uuid";
import BooleanExpressionGroup from "@/views/pages/middleware/MiddlewareManagement/Components/Comparison/refactored/BooleanExpressionGroup.vue"

import LogicGroupClass from "@/custom/class/LogicExpression/logicGroupClass.js"


export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BButton,
    BFormTags,
    BCollapse,
    BooleanExpressionGroup,
  },
  props: {
    variables: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      expression: [],
      logicalConnectors: new Comparisons().items,
      uuidMap: {},
      uuidv4,
      logicExpressionData: undefined,
    }
  },
  computed: {
    field: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    logicExpressionData:{
      handler(newValue, oldValue){
        this.field = JSON.stringify(newValue)
      },
      deep: true
    }
  },
  methods: {
    init() {
      let startData = this.field

      if (startData){
        this.logicExpressionData = JSON.parse(startData)
      } else {
        this.logicExpressionData = new LogicGroupClass();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.logic-expression-container{
  margin-top: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

</style>